<template>
<div class='d-flex align-items-center h-100'>
    <div class='stap-inner'>
        <h1><span class='stap-number'>3.</span> Cursus</h1>
        <h5 class='stap-beschrijving '>Selecteer een cursus voor {{aanmelding.client.firstname}}.</h5>
        <div id="check-onze-site" class='d-block d-md-inline-flex align-items-center'>
            <img src="@/assets/overview.jpeg" alt="Skipiste Nieuwgein vanaf boven" class='img-fluid' id='check-site-image'>
            <div class='ms-3 pt-3 pt-md-0'>
                <h4 class="d-none d-md-block mb-0"><strong>Actuele informatie</strong></h4>
                <h6>Op onze website vind je een uitgebreide informatiepagina met de beschikbare cursussen en prijzen.</h6>
                <a href="https://skipistenieuwegein.nl/cursusoverzicht" target='_blank' class="btn btn-primary">Bekijk informatiepagina</a>
            </div>
        </div>
        <div class='row mt-5'>
            <div id='beschikbare-cursussen' class="w-100" v-if="cursussen.length > 0" >
                    <div
                        v-for="productOption in cursussen" :key="productOption.id"
                        class='card-option'
                        :class="isSelected(productOption.id)"
                        @click='setProduct($event, productOption)'
                    >
                        <h5 class='h-themed'>{{productOption.titel}}</h5>
                        <h6>{{productOption.periode}}</h6>
                        <p>{{productOption.omschrijving}}</p>

                    </div>
            </div>
             <div v-else>
                    <h2>Cursussen laden</h2>
                </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
  name: 'StapCursus',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    setProduct: function (e, cursus) {
      this.loading = true
      this.$store.dispatch('aanmelding/setCursus', cursus)
      this.$router.push({ name: 'StapMomenten' })
      this.loading = false
    },
    isSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.cursus && this.$store.state.aanmelding.cursus.id === id
      }
    }
  },
  computed: {
    aanmelding () {
      return this.$store.state.aanmelding
    },
    cursussen () {
      return this.$store.state.options.cursussen
    },
    selectedCursus () {
      return this.$store.state.aanmelding.cursus
    },
    fullfill () {
      return !!this.$store.state.aanmelding.cursus
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('options/getCursussen')
  }
}
</script>

<style scoped>
    #check-onze-site{
        background: var(--background-light);
        border-radius: 20px;
        padding: 12px;
    }
    #check-onze-site h6{
        color: rgba(0, 0, 0, 0.7);
    }
    #check-site-image{
        border-radius: 15px;
        width: 240px;
    }
    .card{
        border-radius: 20px;
        border: 2px solid #fff
    }
    #extra-info {
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
        line-height: 1.5;
        font-weight: 500;
        min-height: calc(100vh - 300px);
        display: flex;
        align-items: center;
    }
    h6{
        color: rgba(0, 0, 0, 0.87)
    }
    .selection h3{
        font-size: 2rem;
    }
    #selectie-wrapper{
        margin-top: 19px;
        top: 15px;
    }
    .selection{
        background: #fff;
        padding: 24px;
        /* -webkit-box-shadow: 0px 2px 4px 0px #DCD5D5;
        box-shadow: 0px 2px 4px 0px #DCD5D5; */
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        border-radius: 20px;
        text-align: center;
    }
    /* .card{
        border: 2px solid #fff;
    } */
    .card.selected{
        border: 2px solid #00a09f;
    }
    #beschikbare-cursussen{
        margin-bottom: 45px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }
    #beschikbare-cursussen .card-option{
        margin: 0;
        padding: 24px
    }
    #beschikbare-cursussen .card-option h5{
        margin-bottom: 0;
        font-weight: 700;
    }
    #beschikbare-cursussen .card-option h6{
        margin-bottom: 0;
        font-weight: 300;
    }

     #beschikbare-cursussen .card-option p{
        margin-bottom: 0;
        font-weight: 300;
    }
    @media screen and (max-width: 768px) {
        #beschikbare-cursussen{
            grid-template-columns: 1fr;
        }
        #extra-info {
            min-height: auto;
        }
        .selection{
            position: fixed;
            width: 100%;
            bottom: 70px;
            background: #fff;
            box-shadow: none;
            border-top: 1px solid rgba(0, 0, 0, 0.16);
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding: 12px;
            left: 0;
            z-index: 999;
            border-radius: 0;
        }
        .selection h4{
            font-size: 14px;
            margin-bottom: 0;
        }
        .selection h6{
            margin-bottom: 0;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
        }

        #check-site-image{
            border-radius: 15px;
            width: 100%;
            height: 120px;
            display: none;
        }

    }
</style>
